import React from "react";

function ContactMessage() {
  return (
    <div className="d-flex justify-content-center">
      <p className="fs-xxs">
        Masz pytania lub potrzebujesz pomocy?{" "}
        <a href="https://gleevery.com/kontakt" target="_blank" rel="noreferrer">
          Skontaktuj się z nami
        </a>
      </p>
    </div>
  );
}

export default ContactMessage;
